'use client';

import { useParams } from 'next/navigation';
import { Locale } from '../../../../i18n-config';
import { useTranslation } from '@/i18n/client';
import Image from 'next/image';

export default function Custom404() {
  const params = useParams();
  const { t } = useTranslation(params.lang as Locale);

  return (
    <div className="tw-min-h-dvh tw-flex tw-flex-col tw-items-center tw-justify-center">
      <h1>Page not found!</h1>
    </div>
  );
}
